@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
body{
    margin:0px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
html{
    font-size: 10px;
}
.form-wrapper{
    position: relative;
    width:36rem;
}
.form-wrapper + i{
    color:black;
    font-size: 2.6rem;
}
.form-wrapper i{
    color:black;
    font-size: 2rem;
    position: absolute;
    bottom:0;
    left:0;
    margin: 12px;
}
header{
    position: sticky;
    top: 0;
    z-index: 999;
}
nav{
    background-color: #FFF;
    padding:12px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.col-whi{
    color: #fff;
}
.input-control{
    padding:14px 36px;
    width:100%;
    border-radius: 4px;
    border:1px solid #ccc;
    font-size: 1.7rem;

}
.input-control:focus{
    outline:none;
    border:1px solid blueviolet;
}
.card{
    width:260px;
    padding:20px;
    margin: 10px 0px;
    border: 1px solid #fff;
}
.card:hover{
    border:1px solid #ccc;
}
.food-title{
    font-size: 1.6rem;
    color: #2f2c3e;
}
.mt-0{
    margin-top: 0px;
}
.mb-0{
    margin-bottom: 0px;
}
.food-cui{
    color: #686b78;
    font-size: 13px;
    margin-top: 4px
}
.card-img{
    position: relative;
}
.card-img p{
    position: absolute;
    background-color:#3b3d41;
    font-weight: bold;
    padding:6px 8px;
    color:white;
    font-size: 1.2rem;
}
.d-flex{
    display:flex;
    justify-content: center;
    padding:60px 10%;
    flex-wrap: wrap;
    /* z-index: -999; */
}

.offer{
    color:#c1a79e;
    font-size: 1.2rem;
}
.d-none{
    display: none;
}

.car-flex{
    display:flex;
    justify-content: center;
    overflow-x: scroll;
    padding:40px 10%;
    background-color: #161a28;
    gap:4rem;
}
.footer-wrapper{
    padding: 20px 10%;
    background-color:#fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.copy{
    font-size: 2rem;
}
.social-handle{
    display:flex;
    gap:1rem;
    list-style-type: none;
}
.social-handle li i{
    color:rgb(20,16,11);
    font-size: 2.2rem;
    opacity:0.6;
}
.social-handle li i:hover{
    opacity:1;
}
.try-one{
    position: absolute;
    background-color:rgb(43, 146, 43) !important;
    font-weight: bold;
    padding:4px 8px;
    color:white;
    font-size: 1.2rem;
}

@media (max-width:1000px) {
    
    .footer-wrapper{
        padding: 30px 4%;
    }
    
}