@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css";
body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

html {
  font-size: 10px;
}

.form-wrapper {
  width: 36rem;
  position: relative;
}

.form-wrapper + i {
  color: #000;
  font-size: 2.6rem;
}

.form-wrapper i {
  color: #000;
  margin: 12px;
  font-size: 2rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

header {
  z-index: 999;
  position: sticky;
  top: 0;
}

nav {
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10%;
  display: flex;
}

.col-whi {
  color: #fff;
}

.input-control {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 14px 36px;
  font-size: 1.7rem;
}

.input-control:focus {
  border: 1px solid #8a2be2;
  outline: none;
}

.card {
  width: 260px;
  border: 1px solid #fff;
  margin: 10px 0;
  padding: 20px;
}

.card:hover {
  border: 1px solid #ccc;
}

.food-title {
  color: #2f2c3e;
  font-size: 1.6rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.food-cui {
  color: #686b78;
  margin-top: 4px;
  font-size: 13px;
}

.card-img {
  position: relative;
}

.card-img p {
  color: #fff;
  background-color: #3b3d41;
  padding: 6px 8px;
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
}

.d-flex {
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 10%;
  display: flex;
}

.offer {
  color: #c1a79e;
  font-size: 1.2rem;
}

.d-none {
  display: none;
}

.car-flex {
  background-color: #161a28;
  justify-content: center;
  gap: 4rem;
  padding: 40px 10%;
  display: flex;
  overflow-x: scroll;
}

.footer-wrapper {
  background-color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10%;
  display: flex;
}

.copy {
  font-size: 2rem;
}

.social-handle {
  gap: 1rem;
  list-style-type: none;
  display: flex;
}

.social-handle li i {
  color: #14100b;
  opacity: .6;
  font-size: 2.2rem;
}

.social-handle li i:hover {
  opacity: 1;
}

.try-one {
  color: #fff;
  padding: 4px 8px;
  font-size: 1.2rem;
  font-weight: bold;
  position: absolute;
  background-color: #2b922b !important;
}

@media (max-width: 1000px) {
  .footer-wrapper {
    padding: 30px 4%;
  }
}

/*# sourceMappingURL=index.3ed260fe.css.map */
